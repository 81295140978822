import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
} from 'react-places-autocomplete'
import { Button, Form } from 'react-bootstrap'
import { getIn, setIn } from 'formik'
// import { getLocationAutoComplete } from '../actions'
import {
  getPlaceDetailsById,
  getPlaceDetailsByTextSearch,
  logger,
} from '../actions'
import _ from 'lodash'
import { countryNames } from 'constants/CountryList'

const { Control, Group, Label } = Form

const LocationAutocomplete = ({
  field = {},
  form: {
    touched = {},
    errors = {},
    setFieldValue,
    setTouched = () => {},
  } = {},
  onAddLocation,
  isClone = false,
  isUpdate = false,
  label = '',
  controlId = '',
  showPin = false,
  confirmOnSelect = false,
  needFullAddress = false,
  needAddress = false,
  needCityState = false,
  needCityCountry = false,
  needCityNameOnly = false,
  countriesList = false,
  needState = false,
  needBounds = false,
  displayKey = '',
  lightBg = false,
  disabled = false,
  isSearchPickup = false,
  handleChange,
  onHandleChange,
  placeholder = 'Type location...',
  autoFocus,
  getPlaceDetailsByTextSearch,
  getPlaceDetailsById,
  // getLocationAutoComplete,
  ...props
}) => {
  const [query, setQuery] = useState('')
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [suggestion, setSuggestion] = useState(null)
  // const [customSuggestions, setCustomSuggestions] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [active, setActive] = useState('')
  const showError =
    !!field.name &&
    !!errors &&
    !!getIn(touched, field.name) &&
    !!getIn(errors, field.name)

  const onChange = query => {
    setShowLoader(true)
    setActive('')
    if (setFieldValue && field && !confirmOnSelect) {
      setFieldValue(field.name, query)
      setActive('active')
      if (handleChange) {
        handleChange(query)
      }
    } else {
      if (setFieldValue && field && field.value) {
        setFieldValue(field.name, null)
        // setActive('dis-active')
        if (handleChange) {
          handleChange(null)
        }
      }
      setQuery(query)
    }
  }
  const onSelect = async (location, placeId, suggestion) => {
    const isAirport = suggestion.types.includes('airport')
    if (setFieldValue && field) {
      const item = await onConfirm(location, isAirport, suggestion)
      setActive('active')
      setFieldValue(field.name, item)
      if (isSearchPickup) {
        setFieldValue('dropOffLocation', item)
      }
      if (handleChange) {
        handleChange(item)
      }
      if (onHandleChange) {
        onHandleChange()
      }
    } else {
      setSelectedLocation(location)
      setSuggestion(suggestion)
    }
  }

  const checkIsTrain = types => {
    let isTrain = false
    var typesTrain = [
      'subway_station',
      'train_station',
      'transit_station',
      'railway',
    ]
    _.each(typesTrain, train => {
      let index = _.indexOf(types, train)
      if (!isTrain && index !== -1) {
        isTrain = true
      }
    })
    return isTrain
  }

  useEffect(() => {
    if (!!field.value) setActive('active')
  }, [field])

  const findItemAddressComponent = (address_components, level) => {
    return address_components.find(item => item.types.includes(level))
  }

  const findCityName = (address_components, location) => {
    let seachOne = address_components.find(
      item =>
        item.types.includes('locality') && item.types.includes('political')
    )
    if (!seachOne) {
      let searchTwo = address_components.find(item =>
        item.types.includes('postal_town')
      )
      if (!searchTwo) {
        if (location === 'Ibiza, Spain') {
          let searchThree = address_components.find(item =>
            item.types.includes('establishment')
          )
          return searchThree
        }

        return address_components.find(item => item.types.includes('political'))
      } else {
        return searchTwo
      }
    } else {
      return seachOne
    }
    // return address_components.find(
    //   item =>
    //     (item.types.includes('locality') && item.types.includes('political')) ||
    //     item.types.includes('postal_town') ||
    //     item.types.includes('political')
    // )
  }

  const onConfirm = async (_selectedLocation, isAirport, _suggestion) => {
    try {
      var location = _selectedLocation ? _selectedLocation : selectedLocation
      var suggestionObj = _suggestion ? _suggestion : suggestion
      if (location === 'TLV, Israel') {
        location = 'Ben Gurion Airport, Israel'
      }
      if (location === 'Airport/Termin 3, TLV, Israel') {
        location = 'Ben Gurion Airport, Israel'
      } else if (
        location ===
        'Firenze S.M.N., Piazza della Stazione, Florence, Metropolitan City of Florence, Italy'
      ) {
        location =
          'Firenze Santa Maria Novella, Piazza della Stazione, Florence, Metropolitan City of Florence, Italy'
      }

      if (location.includes('&')) {
        let str = location
        str = str.replace(/&/g, ' and ')
        str = str.replace(/( and )+/g, ' and ')

        location = str
      }
      if (location) {
        let placeId,
          city,
          state,
          country,
          geo,
          geoJson = null,
          postCode = null,
          geoBounds = null,
          isAirportType = false,
          isTrain = false,
          isStadium = false,
          isPort = false,
          isHotel = false,
          isMetro = false

        let results = []
        if (location.includes('(CDG)') || location.includes('(cdg)')) {
          results = await getPlaceDetailsById('ChIJgWgYfEA_5kcRZyMX755n2DI')
        } else if (location === 'Amanzoe, Agios Panteleimonas, Greece') {
          results = await getPlaceDetailsById('ChIJD9XVrpqWnxQRVZS4ikgSrgk')
        } else if (location.includes('(MAD)') || location.includes('(mad)')) {
          results = await getPlaceDetailsById('ChIJhbDdh9gxQg0RESnYArK-mOw')
        } else if (location.includes('(DOH)') || location.includes('(doh)')) {
          results = await getPlaceDetailsById('ChIJpdZYwbDIRT4R0zDtL-03UYU')
        } else {
          if (suggestionObj) {
            results = await getPlaceDetailsById(
              suggestionObj.placeId || suggestionObj.place_id
            )
          }
        }
        if (!results || !results.data || !results.data.info) {
          results = await getPlaceDetailsByTextSearch(location)
        }
        if (results && results.data && results.data.info) {
          const place = results.data.info.result
          const { geometry, address_components, place_id } = place
          placeId = place_id
          geo = geometry
          postCode = (
            findItemAddressComponent(address_components, 'postal_code') || {}
          ).short_name

          country = (
            findItemAddressComponent(address_components, 'country') || {}
          ).short_name

          state = (
            findItemAddressComponent(
              address_components,
              'administrative_area_level_1'
            ) ||
            findItemAddressComponent(
              address_components,
              'administrative_area_level_2'
            ) ||
            {}
          ).short_name

          city =
            (findCityName(address_components, location) || {}).long_name ===
              'Roma' && country === 'IT'
              ? 'Rome'
              : (findCityName(address_components, location) || {}).long_name ===
                  'Iraklio' && country === 'GR'
              ? 'Heraklion'
              : (findCityName(address_components, location) || {}).long_name ||
                address_components[0].long_name

          isAirportType =
            location.toLowerCase().search('airport') !== -1 ||
            (location.toLowerCase().search('terminal') !== -1 &&
              location.toLowerCase().search('cruise terminal') === -1) ||
            location.toLowerCase().search('aeropuerto') !== -1 ||
            location.toLowerCase().search('aeroporto') !== -1 ||
            location.toLowerCase().search('aéroport') !== -1 ||
            location.toLowerCase().search('mxp t1') !== -1 ||
            location.search('LHR') !== -1 ||
            location.search('(ICN)') !== -1 ||
            location.search('4561 West Empire Avenue, Burbank') !== -1
              ? !location.toLowerCase().includes('ihg hotel')
                ? true
                : false
              : _.indexOf(place.types, 'airport') !== -1 ||
                _.indexOf(place.types, 'aeroway') !== -1
              ? true
              : false

          isTrain =
            location.toLowerCase().search('airport') !== -1
              ? false
              : location.toLowerCase().search('train station') !== -1
              ? true
              : checkIsTrain(place.types)

          isStadium =
            location.search('Stadium') !== -1 ||
            location.search('Arena') !== -1 ||
            location.search('Stade') !== -1 ||
            _.indexOf(place.types, 'stadium') !== -1

          isPort = place.name
            ? place.name.match(new RegExp('\\b' + 'Port' + '\\b')) !== null ||
              place.name.match(
                new RegExp('\\b' + 'Cruise Terminal' + '\\b')
              ) !== null
            : false

          isHotel =
            location.search('Hotel') !== -1 ||
            location.search('Hôtel') !== -1 ||
            location.search('Resort') !== -1 ||
            _.indexOf(place.types, 'lodging') !== -1
              ? true
              : false

          if (isTrain) {
            isMetro =
              location.toLowerCase().search('metro station') !== -1
                ? true
                : false
          }
        }

        // send returned item
        let item = {
          city: city || location,
          placeId: placeId,
          // city: city, (duplicate key)
          state: state || '',
          country: country || '',
          ...(geoJson ? geoJson : null),
          // ...(geo ? geo.location.toJSON() : null),
          ...(geo
            ? typeof geo.location === 'function'
              ? geo.location.toJSON()
              : geo.location
            : null),
          ...(postCode ? { postCode: postCode } : {}),
          ...(needCityNameOnly ? { cityName: city } : {}),
          locationType: isAirportType
            ? 'isAirport'
            : isMetro
            ? 'isMetro'
            : isTrain
            ? 'isTrain'
            : isStadium
            ? 'isStadium'
            : isPort
            ? 'isPort'
            : isHotel
            ? 'isHotel'
            : 'other',
        }
        if (geo && geo.bounds && needBounds) {
          item.bounds =
            typeof geo.bounds === 'function'
              ? geo.bounds.toJSON()
              : {
                  east: geo.bounds.northeast.lng,
                  north: geo.bounds.northeast.lat,
                  south: geo.bounds.southwest.lat,
                  west: geo.bounds.southwest.lng,
                }
        } else if (geo && geo.viewport && needBounds) {
          item.bounds =
            typeof geo.viewport === 'function'
              ? geo.viewport.toJSON()
              : {
                  east: geo.viewport.northeast.lng,
                  north: geo.viewport.northeast.lat,
                  south: geo.viewport.southwest.lat,
                  west: geo.viewport.southwest.lng,
                }
        } else if (geoBounds && geoBounds.bounds && needBounds) {
          item.bounds = geoBounds.bounds
        }
        if (needFullAddress) {
          if (postCode) {
            let addressWithPostCode =
              country === 'GB'
                ? location.replace(
                    city + ', UK',
                    city + ', ' + postCode + ', UK'
                  )
                : country === 'CA'
                ? location.replace(
                    state + ', Canada',
                    state + ', ' + postCode + ', Canada'
                  )
                : country === 'NZ'
                ? location.replace(
                    city + ', New Zealand',
                    city + ', ' + postCode + ', New Zealand'
                  )
                : country === 'US'
                ? location.replace(', USA', ', ' + postCode + ', USA')
                : location.replace(
                    city + ', ' + country,
                    city + ', ' + postCode + ', ' + country
                  )
            item.fullAddress = addressWithPostCode
          } else {
            item.fullAddress = location
          }
        }
        if (needCityState) {
          if (placeId === 'ChIJBQ07-vf7mkcRKxqfnGm-7KY') {
            item.cityState = 'Bodensee, Germany'
          } else if (placeId === 'ChIJB2SfQXxvjEcRqqCei3C8hlg') {
            item.cityState = 'Vésenaz, Switzerland'
          } else {
            item.cityState = city + ', ' + (state ? state : country)
          }
        }
        if (needCityCountry) {
          const countryCode = country
          const countryData = countryNames.find(c => c.code === countryCode)
          const countryFullName = countryData ? countryData.name : null

          if (country === 'US' || country === 'CA') {
            item.cityCountry =
              city +
              ', ' +
              state +
              ', ' +
              (countryFullName ? countryFullName : countryCode)
          } else {
            item.cityCountry =
              city + ', ' + (countryFullName ? countryFullName : countryCode)
          }
        }
        if (city === 'Jerusalem' && !country) {
          item.country = 'IL'
        }

        if (onAddLocation) {
          onAddLocation(item)
        }
        setQuery('')
        setSelectedLocation(null)
        setSuggestion(null)
        return item
      }

      return null
    } catch (error) {
      logger({ fileName: 'locationAutoComplete', error: error })
      console.log(error)
    }
  }

  //initializing variables
  var sortedArray, arrayOfValues

  // useEffect(() => {
  //   const fetch = async value => {
  //     try {
  //       const result = await getLocationAutoComplete(value)
  //       if (result.data.info.status === "OK") {
  //         setCustomSuggestions(result.data.info.predictions)
  //       }
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   }
  //   if (query) {
  //     fetch(query)
  //   }
  // }, [query, setCustomSuggestions])

  const sortingAlgorithm = unSortedArray => {
    // if (!props.searchOptions) {
    //   if (customSuggestions.length) {
    //     unSortedArray = customSuggestions
    //   }
    // }
    // 1.airport 2.light_rail_station 3.train_station
    // 4.transit_station 5.logging 6.resturant 7.bar
    // 8.Club 9.everything else

    if (unSortedArray.length !== 0) {
      //initializing empty arrays
      sortedArray = []
      arrayOfValues = []

      _.each(unSortedArray, (item, index) => {
        if (item['types'] && item['types'].includes('airport')) {
          arrayOfValues.push({ priority: 1, icon: 'Airport', ...item })
        } else if (
          item['types'] &&
          item['types'].includes('light_rail_station')
        ) {
          arrayOfValues.push({ priority: 2, icon: 'Train_station', ...item })
        } else if (item['types'] && item['types'].includes('train_station')) {
          arrayOfValues.push({ priority: 3, icon: 'Train_station', ...item })
        } else if (
          item['description'] &&
          item['description'].includes('Train Station')
        ) {
          arrayOfValues.push({ priority: 3, icon: 'Train_station', ...item })
        } else if (
          item['description'] &&
          item['description'].includes('Metro Station')
        ) {
          arrayOfValues.push({ priority: 3, icon: 'Train_station', ...item })
        } else if (item['types'] && item['types'].includes('transit_station')) {
          arrayOfValues.push({ priority: 4, icon: 'Port', ...item })
        } else if (item['types'] && item['types'].includes('lodging')) {
          arrayOfValues.push({ priority: 5, icon: 'Hotel', ...item })
        } else if (item['types'] && item['types'].includes('restaurant')) {
          arrayOfValues.push({ priority: 6, icon: 'Restaurant', ...item })
        } else if (item['types'] && item['types'].includes('bar')) {
          arrayOfValues.push({ priority: 7, icon: 'Bar_and_Club', ...item })
        } else if (item['types'] && item['types'].includes('club')) {
          arrayOfValues.push({ priority: 8, icon: 'Bar_and_Club', ...item })
        } else {
          arrayOfValues.push({ priority: 9, icon: 'Location', ...item })
        }
      })
      sortedArray = _.sortBy(arrayOfValues, ['priority'])
    }
    if (sortedArray && sortedArray.length > 0) {
      setShowLoader(false)
    }
    return sortedArray
  }

  const airportSearch = unSortedArray => {
    if (unSortedArray.length !== 0) {
      sortedArray = []
      arrayOfValues = []

      _.each(unSortedArray, item => {
        if (item['types'].includes('country')) {
          arrayOfValues.push({ priority: 1, icon: '', ...item })
        }
      })
      sortedArray = _.sortBy(arrayOfValues, ['priority'])
    }
    return sortedArray
  }

  const displayValue =
    field.value && displayKey && field.value[displayKey]
      ? field.value[displayKey]
      : ''

  const onBlur = () => {
    const _touched = setIn({ ...touched }, field.name, true)
    setTouched(_touched)
  }
  const searchOptions = {
    // types:
    //   props.searchOptions &&
    //   props.searchOptions.types &&
    //   props.searchOptions.types.includes('(cities)')
    //     ? ['cities']
    //     : ['establishment'],
    types: ['(regions)'],
    componentRestrictions: { country: ['us', 'de'] },
  }
  return (
    <PlacesAutocomplete
      {...field}
      value={selectedLocation || query || displayValue}
      onChange={onChange}
      onSelect={onSelect}
      searchOptions={searchOptions}
      onError={err => {
        setActive('dis-active')
        console.log(err)
      }}
      {...props}
      debounce={1000}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        suggestions = countriesList
          ? airportSearch(suggestions)
          : sortingAlgorithm(suggestions)
        // const suggestions = sortingAlgorithm(customSuggestions)
        return (
          <>
            <Group className='' controlId={controlId}>
              {!!label && <Label>{label}</Label>}
              <Control
                {...getInputProps({
                  placeholder,
                  className: `location-autocomplete-control ${
                    showPin
                      ? !showLoader
                        ? 'show-pin'
                        : 'flightTracking-loader-register'
                      : ''
                  }
                  ${active}
                  ${lightBg ? 'light-bg-control' : ''}
                  ${props.inputClass ? props.inputClass : ''}
                  `,
                  disabled: !!disabled,
                  onBlur,
                })}
                isInvalid={showError}
                autoFocus={autoFocus}
              />
              {!!suggestions && !!suggestions.length && (
                <div className='location-autocomplete-result'>
                  {suggestions.map(item => {
                    const itemProps = getSuggestionItemProps(item)
                    return (
                      <div
                        {...itemProps}
                        className={`location-autocomplete-item ${
                          item.active ? 'active' : ''
                        }`}
                      >
                        {/* <span>{item.description}</span> */}
                        <span className='d-flex align-self-start'>
                          <div>
                            <img
                              className=' pr-2'
                              style={{ maxWidth: '30px' }}
                              src={`/images/mapIcons/Icon_${item.icon}.png`}
                              alt=''
                            />
                          </div>
                          {item.icon === 'Hotel' ? (
                            <div style={{ paddingTop: '4.5px' }}>
                              {item.description}
                            </div>
                          ) : (
                            <div style={{ paddingTop: '5px' }}>
                              {item.description}
                            </div>
                          )}
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
              {!!showError && (
                <Control.Feedback type='invalid' className='d-block'>
                  {getIn(errors, field.name)}
                </Control.Feedback>
              )}
            </Group>
            {!!onAddLocation && (
              <Button
                onClick={() => onConfirm()}
                type='button'
                className='text-white'
                block
                disabled={!selectedLocation}
                variant='brown'
              >
                {!!isClone
                  ? 'CONFIRM CLONING'
                  : !!isUpdate
                  ? 'CONFIRM UPDATE'
                  : 'ADD LOCATION'}
              </Button>
            )}
          </>
        )
      }}
    </PlacesAutocomplete>
  )
}

// export default LocationAutocomplete
export default withRouter(
  connect(null, {
    getPlaceDetailsByTextSearch,
    getPlaceDetailsById,
  })(LocationAutocomplete)
)
