import { createOrEditItem, deleteItem } from './form'

export const updatePayoutstatus = (id, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `payout/items/${id}`,
      isEdit: true,
      successMessage: 'Payout was updated successfully.',
    })
  )
export const addBankInfo = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `bankingInfo/${id}`,
      successMessage: 'Bank details was added successfully.',
    })
  )
export const updateBankInfo = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `bankingInfo/${id}`,
      isEdit: true,
      successMessage: 'Bank details was updated successfully.',
    })
  )

export const deleteBankInfo = id => async dispatch =>
  await dispatch(
    deleteItem({
      endpoint: `bankingInfo/${id}`,
      successMessage: 'Bank details was successfully deleted.',
    })
  )

export const updatePayoutDetails = (values, id) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `payout/update/${id}`,
      isEdit: true,
      successMessage: 'Payout summary was updated successfully.',
    })
  )
export const refreshPayoutRates = id => async dispatch =>
  await dispatch(
    createOrEditItem(null, {
      endpoint: `payout/update/rate/${id}`,
      isEdit: true,
      successMessage: 'Payout rates were updated successfully.',
    })
  )
export const approvePayoutItems = (id, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `payout/approve/${id}`,
      isEdit: true,
      successMessage: 'Payout items were updated successfully.',
    })
  )
export const unApprovePayoutItems = (id, values) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      endpoint: `payout/approve/${id}`,
      successMessage: 'Payout items were updated successfully.',
    })
  )

export const generateInvoiceFile = payoutId => async dispatch =>
  await dispatch(
    createOrEditItem(
      {},
      {
        isEdit: true,
        editRequest: 'get',
        endpoint: `generateInvoice/${payoutId}`,
        successMessage: 'Excel sheet was successfully generated',
      }
    )
  )

export const sendPayoutEmail = (id, values = {}) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `payout/sendEmail/${id}`,
      successMessage: 'Payout email was sent successfully.',
    })
  )
export const updateSupplierPayment = (values = {}) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `payout/supplier/update/payment`,
      successMessage: 'Payment status was updated successfully.',
    })
  )
export const getPayoutExtraction = (values = {}) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      editRequest: 'get',
      endpoint: `payout/extraction/${values.startDate}/${values.endDate}`,
      successMessage: 'Payment status was updated successfully.',
    })
  )
export const updatePayoutCurrency = (id, values = {}) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `payout/currency/${id}`,
      successMessage: 'Payment status was updated successfully.',
    })
  )
export const updatePayoutSettings = (id, values = {}) => async dispatch =>
  await dispatch(
    createOrEditItem(values, {
      isEdit: true,
      endpoint: `payout/update/terms/${id}`,
    })
  )
