import React, { useEffect, useState, useContext } from 'react'
import { connect, ReactReduxContext } from 'react-redux'
import { Link, NavLink, withRouter, useLocation } from 'react-router-dom'
import {
  logout,
  operationsTab,
  networkTab,
  fleetTab,
  ratesTab,
  financeTab,
  automationTab,
  rolzoLocationsTab,
  accountTab,
  payoutTab,
  analyticsTab,
} from '../../actions'
import { Button, Nav, Badge } from 'react-bootstrap'
import { SmallDown, SmallUp, ExtraSmallHeight } from '../Responsive/breakpoints'
import { isSystemAdmin, isSystemAdminOrManager } from '../../constants/index'
//used to be called operationRoutes
const seprateRoutes = [
  '/alerts-ops',
  '/sales-bookings',
  '/sales-bookings-POC',
  '/sales-quotes',
  '/support-admin',
  '/chat-quotes',
  '/client-events',
]

const checkMainTitles = (title, path) => {
  let hasPath = seprateRoutes.includes(path)
  if (hasPath) {
    switch (title) {
      case 'Support':
        return true
      case 'Alerts':
        return true
      case 'Bookings':
        return true
      case 'Quotes':
        return true
      case 'Client events':
        return true
      default:
        return false
    }
  } else {
    return false
  }
}
const MenuLink = ({
  path,
  title,
  icon,
  // iconActive,
  homeRoute,
  isLight,
  isAdmin,
  isMinimized,
  operationsAlertCount,
  pendingBookingsCount,
  unreadChatsByAdminCount,
  showAlertBadge,
  showComIndicator,
}) => {
  return (
    <NavLink
      className={`nav-link ${isAdmin ? 'mb-1' : ''} ${
        checkMainTitles(title, path) ? 'active' : ''
      }`}
      to={path}
      style={checkMainTitles(title, path) ? { marginTop: '1.5rem' } : {}}
    >
      {isLight || isAdmin || homeRoute ? (
        <SmallUp>
          <img className='nav-link-img' src={'/images/' + icon} alt='' />
          {showComIndicator && path === '/settings' ? (
            <div className='missing-com-indicator' />
          ) : (
            ''
          )}
        </SmallUp>
      ) : (
        <></>
      )}
      <span
        style={
          !checkMainTitles(title, path)
            ? {
                opacity: isMinimized ? '0' : '1',
                transition: 'opacity 0.28s',
              }
            : {}
        }
      >
        <div style={{ display: 'flex', alignContent: 'center' }}>
          {title}
          {title === 'Alerts' && operationsAlertCount > 0 && (
            <div
              className='ml-3 pl-2 pr-2'
              style={{
                color: 'white',
                background: '#d0021b',
                borderRadius: 10,
              }}
            >
              {`${operationsAlertCount}`}
            </div>
          )}
          {showAlertBadge && title === 'Bookings' && pendingBookingsCount > 0 && (
            <div
              className='ml-3 pl-2 pr-2'
              style={{
                color: 'white',
                background: '#d0021b',
                borderRadius: 10,
              }}
            >
              {`${pendingBookingsCount}`}
            </div>
          )}
          {showAlertBadge &&
            title === 'Support' &&
            unreadChatsByAdminCount > 0 && (
              <div
                className='ml-3 pl-2 pr-2'
                style={{
                  color: 'white',
                  background: '#d0021b',
                  borderRadius: 10,
                }}
              >
                {`${unreadChatsByAdminCount}`}
              </div>
            )}
        </div>
      </span>
    </NavLink>
  )
}

const MenuItem = ({
  title,
  path,
  icon,
  iconActive,
  iconInactive,
  homeRoute = false,
  isLight,
  isAdmin,
  isMinimized,
  currentPath,
  childAllowedRoutes,
  operationsAlertCount,
  pendingBookingsCount,
  unreadChatsByAdminCount,
  sentByAdminQuotesCount,
  showAlertBadge = false,
  showIconAdmin,
  showComIndicator,
}) => {
  if (!childAllowedRoutes) {
    return (
      <MenuLink
        title={title}
        // icon={icon}
        icon={
          path && currentPath && currentPath.startsWith(path)
            ? iconActive
            : iconInactive
        }
        // iconInactive={iconInactive}
        // iconActive={iconActive}
        homeRoute={homeRoute}
        isMinimized={isMinimized}
        path={path}
        showIconAdmin={showIconAdmin}
        operationsAlertCount={operationsAlertCount}
        pendingBookingsCount={pendingBookingsCount}
        unreadChatsByAdminCount={unreadChatsByAdminCount}
        sentByAdminQuotesCount={sentByAdminQuotesCount}
        showAlertBadge={showAlertBadge}
        showComIndicator={showComIndicator}
      />
    )
  }

  return (
    <>
      {childAllowedRoutes
        .filter(({ hideInSidebar }) => !hideInSidebar)
        .map(child => (
          <div key={child.path}>
            <MenuLink
              key={child.path}
              title={child.title}
              icon={
                currentPath && currentPath.startsWith(path)
                  ? child.iconActive
                  : child.iconInactive
              }
              isLight={isLight}
              isAdmin={isAdmin}
              isMinimized={isMinimized}
              showIconAdmin={showIconAdmin}
              operationsAlertCount={operationsAlertCount}
              showAlertBadge={showAlertBadge}
              pendingBookingsCount={pendingBookingsCount}
              unreadChatsByAdminCount={unreadChatsByAdminCount}
              sentByAdminQuotesCount={sentByAdminQuotesCount}
              showComIndicator={showComIndicator}
              path={`${path}`}
            />
          </div>
        ))}
    </>
  )
}

function Sidebar({
  logout,
  routes,
  company = null,
  firstName = '',
  lastName = '',
  companyId = null,
  operationsAlertCount = 0,
  pendingBookingsCount = 0,
  unreadChatsByAdminCount = 0,
  sentByAdminQuotesCount = 0,
  adminTabs,
  operationsTab,
  networkTab,
  fleetTab,
  ratesTab,
  financeTab,
  automationTab,
  rolzoLocationsTab,
  accountTab,
  payoutTab,
  systemRoles,
  analyticsTab,
  showComIndicator,
  history,
  pricingModel,
  currentUserEmail,
  isB2C = false,
}) {
  let [isMinimized, setHoverStatus] = useState(true)
  let [showRolzoArrow, setShowRolzoArrow] = useState(false)
  let isLight = companyId ? true : false
  let isAdmin = companyId ? false : true
  let location = useLocation()
  let currentPath = location.pathname

  const payoutRoutes = ['/payouts/clients', '/payouts/suppliers']
  const networkRoutes = [
    '/clients',
    '/partners',
    '/host-agencies',
    '/consortiums',
    '/individuals',
    // '/referrals-page',
  ]
  const accountRoutes = [
    '/dashboard',
    '/access',
    '/whats-new',
    '/cities',
    '/externalApiLogs',
  ]
  const automationRoutes = ['/automation']
  const rolzoLocationsRoutes = ['/rolzoCountries', '/rolzoCities']
  const fleetRoutes = ['/fleet-brands', '/fleet-global', '/fleet-supply']
  const ratesRoutes = [
    '/surge-pricing',
    '/rates-comp',
    '/rates-comp-local',
    // '/event-card-transfers',
    // '/event-card-bth',
    // '/event-card-vip',
    '/rates-anomalies',
    '/events',
    '/meet-and-greet-rates',
    '/rates-monitoring',
    '/rates-global',
    '/rates-supply',
    '/cancellation-policies',
    '/promotion-code',
    '/restricted-locations',
    '/geo-price',
    '/rolzo-gpt',
  ]
  const financeRoutes = [
    '/transactions-payments',
    // '/statementOfAccount',
    '/transactions-invoices',
    '/taxRates',
    '/extractions',
  ]
  const analyticsRoutes = ['/finance', '/accountManagement', '/Sales']
  useEffect(() => {
    //open tab if one of the sections was opened
    if (currentPath) {
      if (networkRoutes.some(item => currentPath.startsWith(item))) {
        adminTabs.network = true
      } else if (fleetRoutes.some(item => currentPath.startsWith(item))) {
        adminTabs.fleet = true
      } else if (ratesRoutes.some(item => currentPath.startsWith(item))) {
        adminTabs.rates = true
      } else if (financeRoutes.some(item => currentPath.startsWith(item))) {
        adminTabs.finance = true
      } else if (accountRoutes.some(item => currentPath.startsWith(item))) {
        adminTabs.account = true
      } else if (automationRoutes.some(item => currentPath.startsWith(item))) {
        adminTabs.automation = true
      } else if (
        rolzoLocationsRoutes.some(item => currentPath.startsWith(item))
      ) {
        adminTabs.rolzoLocationsRoutes = true
      } else if (payoutRoutes.some(item => currentPath.startsWith(item))) {
        adminTabs.payouts = true
      } else if (analyticsRoutes.some(item => currentPath.startsWith(item))) {
        adminTabs.analytics = true
      }
    }
  }, [])

  useEffect(() => {}, [
    unreadChatsByAdminCount,
    pendingBookingsCount,
    operationsAlertCount,
  ])

  function fetchGoogleLogo(fileId) {
    let platform = {
      'https://business.rolzo.com/api': 'business',
      'https://staging.rolzo.com/api': 'staging',
      'http://localhost:3000': 'staging',
      'https://demo.rolzo.com/api': 'demo',
    }
    return `https://storage.googleapis.com/rolzo-platform/${
      platform[process.env.REACT_APP_APIHOST]
    }/images/${fileId}`
  }

  const minimizableStyle = {
    opacity: isMinimized ? '0' : '1',
    transition: 'opacity 0.28s',
    ...(isB2C ? { fontSize: '16px', fontWeight: '500' } : {}),
  }

  // const menuMinimized = minimized => {
  //   if (document.getElementsByClassName('page-wrap').length === 0) {
  //     return
  //   }

  //   if (minimized) {
  //     document
  //       .getElementsByClassName('page-wrap')[0]
  //       .classList.remove('pagewrap-with-menu')
  //   } else {
  //     document
  //       .getElementsByClassName('page-wrap')[0]
  //       .classList.add('pagewrap-with-menu')
  //   }
  // }

  if (currentUserEmail === 'marsel@rolzo.com') {
    return (
      <aside
        id='sidebar'
        className={`sidebar-container ${companyId ? 'sidebar-light' : ''} ${
          (isMinimized && isLight) || (isMinimized && isAdmin)
            ? 'sidebar-minimized-admin'
            : isAdmin
            ? 'sidebar-expanded-admin'
            : 'sidebar-expanded'
        }`}
      >
        <div
          className={`sidebar-content ${
            isMinimized ? 'sidebar-content-minimized-new' : ''
          }`}
          onMouseEnter={() => {
            if (isAdmin) {
              // menuMinimized(isAdmin & false)
              setHoverStatus(isAdmin & false)
            } else {
              // menuMinimized(isLight & false)
              setHoverStatus(isLight & false)
            }
          }}
          onMouseLeave={() => {
            if (isAdmin) {
              // menuMinimized(isAdmin & true)
              setHoverStatus(isAdmin & true)
            } else {
              // menuMinimized(isLight & true)
              setHoverStatus(isLight & true)
            }
          }}
        >
          {!!company ? (
            <>
              <div
                className={`client-logo-wrap ${
                  isMinimized
                    ? 'client-logo-wrap-minimized'
                    : 'client-logo-wrap-expanded'
                }`}
              >
                <img src='/images/rolzo_logo_black.png' alt='' />
              </div>
              <div className='client-info'>
                {company && (company.logo || company.googleLogo) ? (
                  <div
                    className={`company-logo has-logo ${
                      isMinimized
                        ? 'company-logo-minimized'
                        : 'company-logo-expanded'
                    }`}
                  >
                    <img
                      src={
                        company.googleLogo
                          ? fetchGoogleLogo(company.googleLogo)
                          : company.logo
                      }
                      alt=''
                    />
                  </div>
                ) : (
                  <div className='company-logo company-logo-text'>
                    {company.name.substring(0, 1)}
                  </div>
                )}

                <div
                  className={`company-name ${isMinimized ? '' : ''}`}
                  style={minimizableStyle}
                >
                  {company.isRolzoFirstMember ? (
                    <div
                      className='rolzo-first-label-client'
                      onMouseOver={() => {
                        setShowRolzoArrow(true)
                      }}
                      onMouseOut={() => {
                        setShowRolzoArrow(false)
                      }}
                      onClick={() => history.push('/settings/main/rolzoFirst')}
                    >
                      <span>ROLZO First </span>
                      {!showRolzoArrow ? (
                        <img
                          src='/images/icons/rolzo-first-star.png'
                          alt=''
                          style={{
                            height: '13px',
                            width: '13px',
                            marginTop: '0.09rem',
                            marginLeft: '0.2rem',
                          }}
                        />
                      ) : (
                        <img
                          src='/images/icons/rolzo-first-arrow.png'
                          alt=''
                          style={{
                            height: '13px',
                            width: '13px',
                            marginTop: '0.09rem',
                            marginLeft: '0.2rem',
                          }}
                        />
                      )}
                    </div>
                  ) : (company.industry === 'Lifestyle Management' ||
                      company.industry === 'Travel') &&
                    !company.isRolzoFirstMember &&
                    (pricingModel?.commissionable ||
                      pricingModel?.netDiscount ||
                      company?.corporateBenefits?.defaultBenefits
                        ?.commissionWeight ||
                      company?.corporateBenefits?.defaultBenefits
                        ?.discountWeight) &&
                    company.type !== 'supplier' ? (
                    <div
                      className='rolzo-first-redirect'
                      onClick={() => history.push('/settings/main/rolzoFirst')}
                    >
                      <span>ROLZO First </span>
                      <img
                        src='/images/icons/rolzo-first-arrow-gold.png'
                        alt=''
                        style={{
                          height: '17px',
                          width: '17px',
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <div>{company.name}</div>
                </div>
                <div className='user-name' style={minimizableStyle}>
                  {firstName} {lastName}
                </div>
              </div>
            </>
          ) : (
            <>
              <Link
                to='/'
                className={`logo-wrap ${isMinimized ? 'text-left' : ''} `}
                style={
                  isMinimized
                    ? { marginBottom: '6rem' }
                    : { marginBottom: '4.8rem' }
                }
              >
                {isMinimized ? (
                  <img
                    style={{
                      maxWidth: '20px',
                      marginRight: '10px',
                      marginTop: '2.2rem',
                    }}
                    src='/images/icons/icon-rolzo.png'
                    alt=''
                  />
                ) : (
                  <img
                    style={{ marginTop: '1.7rem' }}
                    src='/images/logo.png'
                    alt=''
                  />
                )}
              </Link>
            </>
          )}
          <SmallUp>
            {isAdmin ? (
              // admin's side bar
              <>
                {isMinimized ? (
                  <div className='d-flex flex-column '>
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '20px' }}
                        src='/images/icons/icon-network.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '20px' }}
                        src='/images/icons/icon-fleet.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '20px' }}
                        src='/images/icons/icon-rates.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '20px' }}
                        src='/images/icons/icon-account.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                  </div>
                ) : (
                  <>
                    {/* Network */}
                    <div className='admin-sideBar-group'>
                      <span
                        className={`flex-column font-weight-bold sidebar-nav expandable`}
                        onClick={() => networkTab()}
                      >
                        <div className='nav-link-header nav-link mb-1'>
                          <span className='networkTab'>Network</span>

                          <img
                            className={`nav-link-arrow ${
                              adminTabs.network ? 'rotateArrow' : ''
                            }`}
                            src='/images/icons/icon-tab-white-arrow.png'
                            style={{ maxWidth: '12px' }}
                            alt=''
                          />
                        </div>
                      </span>
                      {adminTabs.network ? (
                        <Nav
                          className='flex-column sidebar-nav'
                          style={{ paddingLeft: '1.3rem' }}
                        >
                          {routes
                            .filter(({ hideInSidebar, path }) => {
                              if (path === '/partners') {
                                return true
                              }

                              return ''
                              // return (
                              //   networkRoutes.includes(path) && !hideInSidebar
                              // )
                            })
                            .map(({ path, ...rest }, i) => (
                              <MenuItem
                                key={String(i)}
                                path={path}
                                showIconAdmin={false}
                                isLight={isLight}
                                isAdmin={isAdmin}
                                isMinimized={isMinimized}
                                currentPath={location.pathname}
                                operationsAlertCount={operationsAlertCount}
                                pendingBookingsCount={pendingBookingsCount}
                                unreadChatsByAdminCount={
                                  unreadChatsByAdminCount
                                }
                                sentByAdminQuotesCount={sentByAdminQuotesCount}
                                {...rest}
                              />
                            ))}
                        </Nav>
                      ) : (
                        ''
                      )}
                    </div>
                    {/* Fleet */}
                    <div className='admin-sideBar-group'>
                      <span
                        className={`flex-column font-weight-bold sidebar-nav expandable`}
                        onClick={() => fleetTab()}
                      >
                        <div className='nav-link-header nav-link mb-1'>
                          <span className='fleetTab'>Fleet</span>
                          <img
                            className={`nav-link-arrow ${
                              adminTabs.fleet ? 'rotateArrow' : ''
                            }`}
                            src='/images/icons/icon-tab-white-arrow.png'
                            style={{ maxWidth: '12px' }}
                            alt=''
                          />
                        </div>
                      </span>
                      {adminTabs.fleet ? (
                        <Nav
                          className='flex-column sidebar-nav'
                          style={{ paddingLeft: '1.3rem' }}
                        >
                          {routes
                            .filter(({ hideInSidebar, path }) => {
                              return (
                                fleetRoutes.includes(path) && !hideInSidebar
                              )
                            })
                            .map(({ path, ...rest }, i) => (
                              <MenuItem
                                key={String(i)}
                                path={path}
                                isLight={isLight}
                                isAdmin={isAdmin}
                                isMinimized={isMinimized}
                                currentPath={location.pathname}
                                operationsAlertCount={operationsAlertCount}
                                pendingBookingsCount={pendingBookingsCount}
                                unreadChatsByAdminCount={
                                  unreadChatsByAdminCount
                                }
                                sentByAdminQuotesCount={sentByAdminQuotesCount}
                                {...rest}
                              />
                            ))}
                        </Nav>
                      ) : (
                        ''
                      )}
                    </div>
                    {/* Rates */}
                    <div className='admin-sideBar-group'>
                      <span
                        className={`flex-column font-weight-bold sidebar-nav expandable`}
                        onClick={() => ratesTab()}
                      >
                        <div className='nav-link-header nav-link mb-1'>
                          <span className='ratesTab'>Rates</span>
                          <img
                            className={`nav-link-arrow ${
                              adminTabs.rates ? 'rotateArrow' : ''
                            }`}
                            src='/images/icons/icon-tab-white-arrow.png'
                            style={{ maxWidth: '12px' }}
                            alt=''
                          />
                        </div>
                      </span>
                      {adminTabs.rates ? (
                        <Nav
                          className='flex-column sidebar-nav'
                          style={{ paddingLeft: '1.3rem' }}
                        >
                          {routes
                            .filter(({ hideInSidebar, path }) => {
                              return (
                                ratesRoutes.includes(path) && !hideInSidebar
                              )
                            })
                            .map(({ path, ...rest }, i) => {
                              return (
                                <MenuItem
                                  key={String(i)}
                                  path={path}
                                  isLight={isLight}
                                  isAdmin={isAdmin}
                                  isMinimized={isMinimized}
                                  currentPath={location.pathname}
                                  operationsAlertCount={operationsAlertCount}
                                  pendingBookingsCount={pendingBookingsCount}
                                  unreadChatsByAdminCount={
                                    unreadChatsByAdminCount
                                  }
                                  sentByAdminQuotesCount={
                                    sentByAdminQuotesCount
                                  }
                                  {...rest}
                                />
                              )
                            })}
                        </Nav>
                      ) : (
                        ''
                      )}
                    </div>
                    {/* Account */}
                    <div className='admin-sideBar-group pl-0'>
                      <span
                        className={`flex-column font-weight-bold sidebar-nav expandable`}
                        onClick={() => accountTab()}
                      >
                        <div className='nav-link-header nav-link mb-1'>
                          <span className='accountTab'>Account</span>
                          <img
                            className={`nav-link-arrow ${
                              adminTabs.account ? 'rotateArrow' : ''
                            }`}
                            src='/images/icons/icon-tab-white-arrow.png'
                            style={{ maxWidth: '12px' }}
                            alt=''
                          />
                        </div>
                      </span>
                      {adminTabs.account ? (
                        <>
                          <Nav
                            className='flex-column sidebar-nav'
                            style={{ paddingLeft: '1.3rem' }}
                          >
                            {routes
                              .filter(({ hideInSidebar, path }) => {
                                // if (['/dashboard'].includes(path)) {
                                return false

                                // return (
                                //   accountRoutes.includes(path) && !hideInSidebar
                                // )
                              })
                              .map(({ path, ...rest }, i) => (
                                <MenuItem
                                  key={String(i)}
                                  path={path}
                                  isLight={isLight}
                                  isAdmin={isAdmin}
                                  isMinimized={isMinimized}
                                  currentPath={location.pathname}
                                  operationsAlertCount={operationsAlertCount}
                                  pendingBookingsCount={pendingBookingsCount}
                                  unreadChatsByAdminCount={
                                    unreadChatsByAdminCount
                                  }
                                  sentByAdminQuotesCount={
                                    sentByAdminQuotesCount
                                  }
                                  {...rest}
                                />
                              ))}
                          </Nav>
                          <Button
                            onClick={logout}
                            variant='link'
                            className='nav-link'
                            style={{ paddingLeft: '1.8rem' }}
                          >
                            <span style={minimizableStyle}>Logout</span>
                          </Button>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              // client's side bar
              <Nav className='flex-column sidebar-nav'>
                {routes
                  .filter(({ hideInSidebar }) => !hideInSidebar)
                  .map(({ path, ...rest }, i) => {
                    return (
                      <MenuItem
                        key={String(i)}
                        path={path}
                        isLight={isLight}
                        isAdmin={isAdmin}
                        isMinimized={isMinimized}
                        currentPath={location.pathname}
                        operationsAlertCount={operationsAlertCount}
                        pendingBookingsCount={pendingBookingsCount}
                        unreadChatsByAdminCount={unreadChatsByAdminCount}
                        showComIndicator={showComIndicator}
                        {...rest}
                      />
                    )
                  })}
              </Nav>
            )}
          </SmallUp>
          <SmallDown>
            <Nav className='flex-column sidebar-nav'>
              {routes
                .filter(({ hideInSidebar }) => !hideInSidebar)
                .map(({ path, ...rest }, i) => (
                  <MenuItem
                    key={String(i)}
                    path={path}
                    currentPath={location.pathname}
                    {...rest}
                  />
                ))}
            </Nav>
          </SmallDown>

          <SmallDown>
            {/* <MenuItem key='chat' path='/chat-client' title='Support' /> */}
            <MenuItem key='chat' path='/chat-client' title='Support 24/7' />
            <MenuItem
              key='notification'
              path='/notifications-client'
              title='Notifications'
            />
          </SmallDown>

          <SmallUp>
            {!isAdmin && (
              <Button onClick={logout} variant='link' className='nav-link'>
                {companyId ? (
                  <SmallUp>
                    <img
                      className='nav-link-img'
                      src='/images/menu_logout.png'
                      alt=''
                    />
                  </SmallUp>
                ) : (
                  ''
                )}
                <span style={minimizableStyle}>Logout</span>
              </Button>
            )}
          </SmallUp>
          <SmallDown>
            <Button onClick={logout} variant='link' className='nav-link'>
              <span>Logout</span>
            </Button>
          </SmallDown>

          <ExtraSmallHeight>
            {companyId ? (
              <div className='business_logo'>
                <img
                  src='/images/rolzo_logo_black.png'
                  alt=''
                  style={minimizableStyle}
                />
              </div>
            ) : (
              ''
            )}
          </ExtraSmallHeight>
        </div>
      </aside>
    )
  }

  return (
    <aside
      id='sidebar'
      className={`sidebar-container ${companyId ? 'sidebar-light' : ''} ${
        (isMinimized && isLight) || (isMinimized && isAdmin)
          ? 'sidebar-minimized-admin'
          : isAdmin
          ? 'sidebar-expanded-admin'
          : 'sidebar-expanded'
      }`}
    >
      <div
        className={`sidebar-content ${
          isMinimized ? 'sidebar-content-minimized-new' : ''
        }`}
        onMouseEnter={() => {
          if (isAdmin) {
            // menuMinimized(isAdmin & false)
            setHoverStatus(isAdmin & false)
          } else {
            // menuMinimized(isLight & false)
            setHoverStatus(isLight & false)
          }
        }}
        onMouseLeave={() => {
          if (isAdmin) {
            // menuMinimized(isAdmin & true)
            setHoverStatus(isAdmin & true)
          } else {
            // menuMinimized(isLight & true)
            setHoverStatus(isLight & true)
          }
        }}
      >
        {!!company ? (
          <>
            <div
              className={`client-logo-wrap ${
                isMinimized
                  ? 'client-logo-wrap-minimized'
                  : 'client-logo-wrap-expanded'
              }`}
            >
              <img src='/images/rolzo_logo_black.png' alt='' />
            </div>
            <div className='client-info'>
              {company && (company.logo || company.googleLogo) ? (
                <div
                  className={`company-logo has-logo ${
                    isMinimized
                      ? 'company-logo-minimized'
                      : 'company-logo-expanded'
                  }`}
                >
                  <img
                    src={
                      company.googleLogo
                        ? fetchGoogleLogo(company.googleLogo)
                        : company.logo
                    }
                    alt=''
                  />
                </div>
              ) : (
                <div className='company-logo company-logo-text'>
                  {company.name.substring(0, 1)}
                </div>
              )}

              <div
                className={`company-name ${isMinimized ? '' : ''}`}
                style={minimizableStyle}
              >
                {company.isRolzoFirstMember ? (
                  <div
                    className='rolzo-first-label-client'
                    onMouseOver={() => {
                      setShowRolzoArrow(true)
                    }}
                    onMouseOut={() => {
                      setShowRolzoArrow(false)
                    }}
                    onClick={() => history.push('/settings/main/rolzoFirst')}
                  >
                    <span>ROLZO First </span>
                    {!showRolzoArrow ? (
                      <img
                        src='/images/icons/rolzo-first-star.png'
                        alt=''
                        style={{
                          height: '13px',
                          width: '13px',
                          marginTop: '0.09rem',
                          marginLeft: '0.2rem',
                        }}
                      />
                    ) : (
                      <img
                        src='/images/icons/rolzo-first-arrow.png'
                        alt=''
                        style={{
                          height: '13px',
                          width: '13px',
                          marginTop: '0.09rem',
                          marginLeft: '0.2rem',
                        }}
                      />
                    )}
                  </div>
                ) : (company.industry === 'Lifestyle Management' ||
                    company.industry === 'Travel') &&
                  !company.isRolzoFirstMember &&
                  ((pricingModel &&
                    (pricingModel.commissionable ||
                      pricingModel.netDiscount)) ||
                    (company.corporateBenefits &&
                      company.corporateBenefits.defaultBenefits &&
                      (company.corporateBenefits.defaultBenefits
                        .commissionWeight ||
                        company.corporateBenefits.defaultBenefits
                          .discountWeight))) &&
                  company.type !== 'supplier' ? (
                  <div
                    className='rolzo-first-redirect'
                    onClick={() => history.push('/settings/main/rolzoFirst')}
                  >
                    <span>ROLZO First </span>
                    <img
                      src='/images/icons/rolzo-first-arrow-gold.png'
                      alt=''
                      style={{
                        height: '17px',
                        width: '17px',
                      }}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {!isB2C ? <div>{company.name}</div> : <></>}
              </div>
              <div className='user-name' style={minimizableStyle}>
                {firstName} {lastName}
              </div>
              {company.type === 'supplier' ? (
                <div style={minimizableStyle}>
                  <Link
                    to='/partner/auth'
                    className='switch-client-btn mt-4'
                    style={{ width: '10rem' }}
                  >
                    <span>Switch to partner</span>
                    <img src='/images/icons/switch-gold.png'></img>
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            <Link
              to='/'
              className={`logo-wrap ${isMinimized ? 'text-left' : ''} `}
              style={
                isMinimized
                  ? { marginBottom: '6rem' }
                  : { marginBottom: '4.8rem' }
              }
            >
              {isMinimized ? (
                <img
                  style={{
                    maxWidth: '20px',
                    marginRight: '10px',
                    marginTop: '2.2rem',
                  }}
                  src='/images/icons/icon-rolzo.png'
                  alt=''
                />
              ) : (
                <img
                  style={{ marginTop: '1.7rem' }}
                  src='/images/logo.png'
                  alt=''
                />
              )}
            </Link>
          </>
        )}
        <SmallUp>
          {isAdmin ? (
            // admin's side bar
            <>
              {isMinimized ? (
                <div className='d-flex flex-column '>
                  {unreadChatsByAdminCount > 0 ? (
                    <div>
                      <img
                        className='admin-sideBar-icons mt-0'
                        style={{ width: '22px' }}
                        src='/images/icons/icon-support.png'
                        alt=''
                      />
                      <div className='admin-support-unread-indicator' />
                    </div>
                  ) : (
                    <div>
                      <img
                        className='admin-sideBar-icons mt-0'
                        style={{ width: '22px' }}
                        src='/images/icons/icon-support.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                  )}
                  {operationsAlertCount > 0 ? (
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '22px' }}
                        src='/images/icons/icon-alerts.png'
                        alt=''
                      />
                      <div className='admin-alerts-unread-indicator' />
                    </div>
                  ) : (
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '22px' }}
                        src='/images/icons/icon-alerts.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                  )}
                  {pendingBookingsCount > 0 ? (
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '22px' }}
                        src='/images/icons/icon-bookings.png'
                        alt=''
                      />
                      <div className='admin-bookings-unread-indicator' />
                    </div>
                  ) : (
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '22px' }}
                        src='/images/icons/icon-bookings.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                  )}
                  <div>
                    <img
                      className='admin-sideBar-icons '
                      style={{ width: '22px' }}
                      src='/images/icons/icon-quotes.png'
                      alt=''
                    />
                    <div className='admin-without-indicator' />
                  </div>
                  {/* <div>
                    <img
                      className='admin-sideBar-icons '
                      style={{ width: '22px' }}
                      src='/images/icons/icon-events.png'
                      alt=''
                    />
                    <div className='admin-without-indicator' />
                  </div> */}
                  {/* test above */}
                  <div>
                    <img
                      className='admin-sideBar-icons'
                      style={{ width: '20px' }}
                      src='/images/icons/icon-network.png'
                      alt=''
                    />
                    <div className='admin-without-indicator' />
                  </div>
                  <div>
                    <img
                      className='admin-sideBar-icons'
                      style={{ width: '20px' }}
                      src='/images/icons/icon-fleet.png'
                      alt=''
                    />
                    <div className='admin-without-indicator' />
                  </div>
                  <div>
                    <img
                      className='admin-sideBar-icons'
                      style={{ width: '20px' }}
                      src='/images/icons/icon-rates.png'
                      alt=''
                    />
                    <div className='admin-without-indicator' />
                  </div>
                  {systemRoles && isSystemAdmin(systemRoles) && (
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '22px', transform: 'translateX(-1px)' }}
                        src='/images/icons/icon-finance.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                  )}
                  {systemRoles && isSystemAdmin(systemRoles) && (
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '22px', transform: 'translateX(-1px)' }}
                        src='/images/icons/icon-payout.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                  )}
                  {systemRoles && isSystemAdmin(systemRoles) && (
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '22px' }}
                        src='/images/icons/icon-locations.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                  )}
                  {systemRoles && isSystemAdmin(systemRoles) && (
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '22px' }}
                        src='/images/icons/icon-automation.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                  )}
                  {/* {systemRoles && isSystemAdmin(systemRoles) && (
                    <div>
                      <img
                        className='admin-sideBar-icons'
                        style={{ width: '22px', transform: 'translateX(-1px)' }}
                        src='/images/icons/analytics.png'
                        alt=''
                      />
                      <div className='admin-without-indicator' />
                    </div>
                  )} */}
                  <div>
                    <img
                      className='admin-sideBar-icons'
                      style={{ width: '20px' }}
                      src='/images/icons/icon-account.png'
                      alt=''
                    />
                    <div className='admin-without-indicator' />
                  </div>
                </div>
              ) : (
                <>
                  <Nav className='flex-column sidebar-nav'>
                    {routes
                      .filter(({ hideInSidebar, showIconAdmin, path }) => {
                        return seprateRoutes.includes(path) && !hideInSidebar
                      })
                      .map(({ path, ...rest }, i) => (
                        <MenuItem
                          key={String(i)}
                          path={path}
                          isLight={isLight}
                          isAdmin={isAdmin}
                          isMinimized={isMinimized}
                          currentPath={location.pathname}
                          operationsAlertCount={operationsAlertCount}
                          pendingBookingsCount={pendingBookingsCount}
                          unreadChatsByAdminCount={unreadChatsByAdminCount}
                          sentByAdminQuotesCount={sentByAdminQuotesCount}
                          {...rest}
                        />
                      ))}
                  </Nav>
                  {/* Network */}
                  <div className='admin-sideBar-group'>
                    <span
                      className={`flex-column font-weight-bold sidebar-nav expandable`}
                      onClick={() => networkTab()}
                    >
                      <div className='nav-link-header nav-link mb-1'>
                        <span className='networkTab'>Network</span>

                        <img
                          className={`nav-link-arrow ${
                            adminTabs.network ? 'rotateArrow' : ''
                          }`}
                          src='/images/icons/icon-tab-white-arrow.png'
                          style={{ maxWidth: '12px' }}
                          alt=''
                        />
                      </div>
                    </span>
                    {adminTabs.network ? (
                      <Nav
                        className='flex-column sidebar-nav'
                        style={{ paddingLeft: '1.3rem' }}
                      >
                        {routes
                          .filter(({ hideInSidebar, path }) => {
                            return (
                              networkRoutes.includes(path) && !hideInSidebar
                            )
                          })
                          .map(({ path, ...rest }, i) => (
                            <MenuItem
                              key={String(i)}
                              path={path}
                              showIconAdmin={false}
                              isLight={isLight}
                              isAdmin={isAdmin}
                              isMinimized={isMinimized}
                              currentPath={location.pathname}
                              operationsAlertCount={operationsAlertCount}
                              pendingBookingsCount={pendingBookingsCount}
                              unreadChatsByAdminCount={unreadChatsByAdminCount}
                              sentByAdminQuotesCount={sentByAdminQuotesCount}
                              {...rest}
                            />
                          ))}
                      </Nav>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* Fleet */}
                  <div className='admin-sideBar-group'>
                    <span
                      className={`flex-column font-weight-bold sidebar-nav expandable`}
                      onClick={() => fleetTab()}
                    >
                      <div className='nav-link-header nav-link mb-1'>
                        <span className='fleetTab'>Fleet</span>
                        <img
                          className={`nav-link-arrow ${
                            adminTabs.fleet ? 'rotateArrow' : ''
                          }`}
                          src='/images/icons/icon-tab-white-arrow.png'
                          style={{ maxWidth: '12px' }}
                          alt=''
                        />
                      </div>
                    </span>
                    {adminTabs.fleet ? (
                      <Nav
                        className='flex-column sidebar-nav'
                        style={{ paddingLeft: '1.3rem' }}
                      >
                        {routes
                          .filter(({ hideInSidebar, path }) => {
                            return fleetRoutes.includes(path) && !hideInSidebar
                          })
                          .map(({ path, ...rest }, i) => (
                            <MenuItem
                              key={String(i)}
                              path={path}
                              isLight={isLight}
                              isAdmin={isAdmin}
                              isMinimized={isMinimized}
                              currentPath={location.pathname}
                              operationsAlertCount={operationsAlertCount}
                              pendingBookingsCount={pendingBookingsCount}
                              unreadChatsByAdminCount={unreadChatsByAdminCount}
                              sentByAdminQuotesCount={sentByAdminQuotesCount}
                              {...rest}
                            />
                          ))}
                      </Nav>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* Rates */}
                  <div className='admin-sideBar-group'>
                    <span
                      className={`flex-column font-weight-bold sidebar-nav expandable`}
                      onClick={() => ratesTab()}
                    >
                      <div className='nav-link-header nav-link mb-1'>
                        <span className='ratesTab'>Rates</span>
                        <img
                          className={`nav-link-arrow ${
                            adminTabs.rates ? 'rotateArrow' : ''
                          }`}
                          src='/images/icons/icon-tab-white-arrow.png'
                          style={{ maxWidth: '12px' }}
                          alt=''
                        />
                      </div>
                    </span>
                    {adminTabs.rates ? (
                      <Nav
                        className='flex-column sidebar-nav'
                        style={{ paddingLeft: '1.3rem' }}
                      >
                        {routes
                          .filter(({ hideInSidebar, path }) => {
                            return ratesRoutes.includes(path) && !hideInSidebar
                          })
                          .map(({ path, ...rest }, i) => (
                            <MenuItem
                              key={String(i)}
                              path={path}
                              isLight={isLight}
                              isAdmin={isAdmin}
                              isMinimized={isMinimized}
                              currentPath={location.pathname}
                              operationsAlertCount={operationsAlertCount}
                              pendingBookingsCount={pendingBookingsCount}
                              unreadChatsByAdminCount={unreadChatsByAdminCount}
                              sentByAdminQuotesCount={sentByAdminQuotesCount}
                              {...rest}
                            />
                          ))}
                      </Nav>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* Finance */}
                  {systemRoles && isSystemAdmin(systemRoles) && (
                    <div className='admin-sideBar-group'>
                      <span
                        className={`flex-column font-weight-bold sidebar-nav expandable`}
                        onClick={() => financeTab()}
                      >
                        <div className='nav-link-header nav-link mb-1'>
                          <span className='financeTab'>Finance</span>
                          <img
                            className={`nav-link-arrow ${
                              adminTabs.finance ? 'rotateArrow' : ''
                            }`}
                            src='/images/icons/icon-tab-white-arrow.png'
                            style={{ maxWidth: '12px' }}
                            alt=''
                          />
                        </div>
                      </span>
                      {adminTabs.finance ? (
                        <Nav
                          className='flex-column sidebar-nav'
                          style={{ paddingLeft: '1.3rem' }}
                        >
                          {routes
                            .filter(({ hideInSidebar, path }) => {
                              return (
                                financeRoutes.includes(path) && !hideInSidebar
                              )
                            })
                            .map(({ path, ...rest }, i) => (
                              <MenuItem
                                key={String(i)}
                                path={path}
                                isLight={isLight}
                                isAdmin={isAdmin}
                                isMinimized={isMinimized}
                                currentPath={location.pathname}
                                operationsAlertCount={operationsAlertCount}
                                pendingBookingsCount={pendingBookingsCount}
                                unreadChatsByAdminCount={
                                  unreadChatsByAdminCount
                                }
                                sentByAdminQuotesCount={sentByAdminQuotesCount}
                                {...rest}
                              />
                            ))}
                        </Nav>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                  {/* Payouts */}
                  {systemRoles && isSystemAdminOrManager(systemRoles) && (
                    <div className='admin-sideBar-group'>
                      <span
                        className={`flex-column font-weight-bold sidebar-nav expandable`}
                        onClick={() => payoutTab()}
                      >
                        <div className='nav-link-header nav-link mb-1'>
                          <span className='payoutTab'>Payouts</span>
                          <img
                            className={`nav-link-arrow ${
                              adminTabs.payouts ? 'rotateArrow' : ''
                            }`}
                            src='/images/icons/icon-tab-white-arrow.png'
                            style={{ maxWidth: '12px' }}
                            alt=''
                          />
                        </div>
                      </span>
                      {adminTabs.payouts ? (
                        <Nav
                          className='flex-column sidebar-nav'
                          style={{ paddingLeft: '1.3rem' }}
                        >
                          {routes
                            .filter(({ hideInSidebar, path }) => {
                              return (
                                payoutRoutes.includes(path) && !hideInSidebar
                              )
                            })
                            .map(({ path, ...rest }, i) => (
                              <MenuItem
                                key={String(i)}
                                path={path}
                                isLight={isLight}
                                isAdmin={isAdmin}
                                isMinimized={isMinimized}
                                currentPath={location.pathname}
                                operationsAlertCount={operationsAlertCount}
                                pendingBookingsCount={pendingBookingsCount}
                                unreadChatsByAdminCount={
                                  unreadChatsByAdminCount
                                }
                                sentByAdminQuotesCount={sentByAdminQuotesCount}
                                {...rest}
                              />
                            ))}
                        </Nav>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                  {/* Rolzo Locations */}
                  {systemRoles && isSystemAdmin(systemRoles) && (
                    <div className='admin-sideBar-group'>
                      <span
                        className={`flex-column font-weight-bold sidebar-nav expandable`}
                        onClick={() => rolzoLocationsTab()}
                      >
                        <div className='nav-link-header nav-link mb-1'>
                          <span className='rolzoLocationsTab'>Locations</span>
                          <img
                            className={`nav-link-arrow ${
                              adminTabs.rolzoLocationsTab ? 'rotateArrow' : ''
                            }`}
                            src='/images/icons/icon-tab-white-arrow.png'
                            style={{ maxWidth: '12px' }}
                            alt=''
                          />
                        </div>
                      </span>
                      {adminTabs.rolzoLocations ? (
                        <Nav
                          className='flex-column sidebar-nav'
                          style={{ paddingLeft: '1.3rem' }}
                        >
                          {routes
                            .filter(({ hideInSidebar, path }) => {
                              return (
                                rolzoLocationsRoutes.includes(path) &&
                                !hideInSidebar
                              )
                            })
                            .map(({ path, ...rest }, i) => (
                              <MenuItem
                                key={String(i)}
                                path={path}
                                isLight={isLight}
                                isAdmin={isAdmin}
                                isMinimized={isMinimized}
                                currentPath={location.pathname}
                                operationsAlertCount={operationsAlertCount}
                                pendingBookingsCount={pendingBookingsCount}
                                unreadChatsByAdminCount={
                                  unreadChatsByAdminCount
                                }
                                sentByAdminQuotesCount={sentByAdminQuotesCount}
                                {...rest}
                              />
                            ))}
                        </Nav>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                  {/* Analytics */}
                  {/* {systemRoles && isSystemAdminOrManager(systemRoles) && (
                    <div className='admin-sideBar-group'>
                      <span
                        className={`flex-column font-weight-bold sidebar-nav expandable`}
                        onClick={() => analyticsTab()}
                      >
                        <div className='nav-link-header nav-link mb-1'>
                          <span className='analyticsTab'>Analytics</span>
                          <img
                            className={`nav-link-arrow ${
                              adminTabs.analytics ? 'rotateArrow' : ''
                            }`}
                            src='/images/icons/icon-tab-white-arrow.png'
                            style={{ maxWidth: '12px' }}
                            alt=''
                          />
                        </div>
                      </span>
                      {adminTabs.analytics ? (
                        <Nav
                          className='flex-column sidebar-nav'
                          style={{ paddingLeft: '1.3rem' }}
                        >
                          {routes
                            .filter(({ hideInSidebar, path }) => {
                              return (
                                analyticsRoutes.includes(path) && !hideInSidebar
                              )
                            })
                            .map(({ path, ...rest }, i) => (
                              <MenuItem
                                key={String(i)}
                                path={path}
                                isLight={isLight}
                                isAdmin={isAdmin}
                                isMinimized={isMinimized}
                                currentPath={location.pathname}
                                {...rest}
                              />
                            ))}
                        </Nav>
                      ) : (
                        ''
                      )}
                    </div>
                  )} */}
                  {systemRoles && isSystemAdmin(systemRoles) && (
                    <div className='admin-sideBar-group'>
                      <span
                        className={`flex-column font-weight-bold sidebar-nav expandable`}
                        onClick={() => automationTab()}
                      >
                        <div className='nav-link-header nav-link mb-1'>
                          <span className='automationTab'>Automation</span>
                          <img
                            className={`nav-link-arrow ${
                              adminTabs.automation ? 'rotateArrow' : ''
                            }`}
                            src='/images/icons/icon-tab-white-arrow.png'
                            style={{ maxWidth: '12px' }}
                            alt=''
                          />
                        </div>
                      </span>
                      {adminTabs.automation ? (
                        <Nav
                          className='flex-column sidebar-nav'
                          style={{ paddingLeft: '1.3rem' }}
                        >
                          {routes
                            .filter(({ hideInSidebar, path }) => {
                              return (
                                automationRoutes.includes(path) &&
                                !hideInSidebar
                              )
                            })
                            .map(({ path, ...rest }, i) => (
                              <MenuItem
                                key={String(i)}
                                path={path}
                                isLight={isLight}
                                isAdmin={isAdmin}
                                isMinimized={isMinimized}
                                currentPath={location.pathname}
                                operationsAlertCount={operationsAlertCount}
                                pendingBookingsCount={pendingBookingsCount}
                                unreadChatsByAdminCount={
                                  unreadChatsByAdminCount
                                }
                                sentByAdminQuotesCount={sentByAdminQuotesCount}
                                {...rest}
                              />
                            ))}
                        </Nav>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                  {/* Account */}
                  <div className='admin-sideBar-group pl-0'>
                    <span
                      className={`flex-column font-weight-bold sidebar-nav expandable`}
                      onClick={() => accountTab()}
                    >
                      <div className='nav-link-header nav-link mb-1'>
                        <span className='accountTab'>Account</span>
                        <img
                          className={`nav-link-arrow ${
                            adminTabs.account ? 'rotateArrow' : ''
                          }`}
                          src='/images/icons/icon-tab-white-arrow.png'
                          style={{ maxWidth: '12px' }}
                          alt=''
                        />
                      </div>
                    </span>
                    {adminTabs.account ? (
                      <>
                        <Nav
                          className='flex-column sidebar-nav'
                          style={{ paddingLeft: '1.3rem' }}
                        >
                          {routes
                            .filter(({ hideInSidebar, path }) => {
                              return (
                                accountRoutes.includes(path) && !hideInSidebar
                              )
                            })
                            .map(({ path, ...rest }, i) => (
                              <MenuItem
                                key={String(i)}
                                path={path}
                                isLight={isLight}
                                isAdmin={isAdmin}
                                isMinimized={isMinimized}
                                currentPath={location.pathname}
                                operationsAlertCount={operationsAlertCount}
                                pendingBookingsCount={pendingBookingsCount}
                                unreadChatsByAdminCount={
                                  unreadChatsByAdminCount
                                }
                                sentByAdminQuotesCount={sentByAdminQuotesCount}
                                {...rest}
                              />
                            ))}
                        </Nav>
                        <Button
                          onClick={logout}
                          variant='link'
                          className='nav-link'
                          style={{ paddingLeft: '1.8rem' }}
                        >
                          <span style={minimizableStyle}>Logout</span>
                        </Button>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            // client's side bar
            <Nav className='flex-column sidebar-nav'>
              {routes
                .filter(({ hideInSidebar }) => !hideInSidebar)
                .map(({ path, ...rest }, i) => {
                  return (
                    <MenuItem
                      key={String(i)}
                      path={path}
                      isLight={isLight}
                      isAdmin={isAdmin}
                      isMinimized={isMinimized}
                      currentPath={location.pathname}
                      operationsAlertCount={operationsAlertCount}
                      pendingBookingsCount={pendingBookingsCount}
                      unreadChatsByAdminCount={unreadChatsByAdminCount}
                      showComIndicator={showComIndicator}
                      {...rest}
                    />
                  )
                })}
            </Nav>
          )}
        </SmallUp>
        <SmallDown>
          <Nav className='flex-column sidebar-nav'>
            {routes
              .filter(({ hideInSidebar }) => !hideInSidebar)
              .map(({ path, ...rest }, i) => (
                <MenuItem
                  key={String(i)}
                  path={path}
                  currentPath={location.pathname}
                  {...rest}
                />
              ))}
          </Nav>
        </SmallDown>

        <SmallDown>
          {/* <MenuItem key='chat' path='/chat-client' title='Support' /> */}
          <MenuItem key='chat' path='/chat-client' title='Support 24/7' />
          <MenuItem
            key='notification'
            path='/notifications-client'
            title='Notifications'
          />
        </SmallDown>

        <SmallUp>
          {!isAdmin && (
            <Button onClick={logout} variant='link' className='nav-link'>
              {companyId ? (
                <SmallUp>
                  <img
                    className='nav-link-img'
                    src='/images/menu_logout.png'
                    alt=''
                  />
                </SmallUp>
              ) : (
                ''
              )}
              <span style={minimizableStyle}>Logout</span>
            </Button>
          )}
        </SmallUp>
        <SmallDown>
          <Button onClick={logout} variant='link' className='nav-link'>
            <span>Logout</span>
          </Button>
        </SmallDown>

        <ExtraSmallHeight>
          {companyId ? (
            <div className='business_logo'>
              <img
                src='/images/rolzo_logo_black.png'
                alt=''
                style={minimizableStyle}
              />
            </div>
          ) : (
            ''
          )}
        </ExtraSmallHeight>
      </div>
    </aside>
  )
}

const mapStateToProps = ({
  auth: {
    company = null,
    firstName,
    lastName,
    companyId,
    roles,
    showComIndicator,
    pricingModel,
    email,
    isB2C,
  } = {},
  appWebsocket,
  adminTabs,
} = {}) => {
  return {
    firstName,
    lastName,
    company,
    companyId,
    adminTabs,
    operationsAlertCount: appWebsocket.operationsAlertCount,
    pendingBookingsCount: appWebsocket.pendingBookingsCount,
    unreadChatsByAdminCount: appWebsocket.unreadChatsByAdminCount,
    showComIndicator,
    systemRoles: roles.system,
    pricingModel: pricingModel,
    currentUserEmail: email,
    isB2C: isB2C,
  }
}

export default withRouter(
  connect(mapStateToProps, {
    logout,
    operationsTab,
    networkTab,
    fleetTab,
    ratesTab,
    financeTab,
    automationTab,
    rolzoLocationsTab,
    accountTab,
    payoutTab,
    analyticsTab,
  })(Sidebar)
)
